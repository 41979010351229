<template>
  <div class="sk-page">
    <simple-table
      :table-column="tableColumn"
      :table-data="tableData"
      :with-pagination="false"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'config_deadline:config'"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.setting') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="bizStatus"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'yes_no', value: scope.row.bizStatus}) }}
      </template>
      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-edit"
          :title="$t('operation.edit')"
          @click="handleEditClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-delete"
          :title="$t('operation.delete')"
          @click="deleteRecord(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>
<script>
import detailDialog from './detail'

const BASEURL = {
  list: '/jlr/mt/monthReportConfig/list'
}

const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'orc', 'nov', 'dec']

export default {
  name: 'ConfigDeadline',
  components: { detailDialog },
  data () {
    return {
      searchModel: { year: '2020' },
      tableData: [],
      rowData: {},
      isViewFlag: false,
      isShowDetailDialog: false
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'year',
          labelWidth: '50px',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('config_deadline.year'),
          component: { type: 'year', valueFormat: 'yyyy' }
        },
        { slotName: 'tableOperation', col: { xs: 20, sm: 20, md: 20, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },
    tableColumn () {
      return [
        {
          label: this.$t('config_deadline.month'),
          prop: 'month',
          minWidth: 160,
          formatter: (data) => {
            return this.$t('config_deadline.' + MONTHS[data.month])
          }
        },
        { label: this.$t('config_deadline.startTime'), prop: 'startTime', minWidth: 200 },
        { label: this.$t('config_deadline.endTime'), prop: 'endTime', minWidth: 200 },
        { label: this.$t('config_deadline.ifStart'), prop: 'bizStatus', minWidth: 160, slotName: 'bizStatus' }
      ]
    }
  },
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      params.page = { orderBy: 'month' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.rowData = {}
      this.isViewFlag = false
      this.isShowDetailDialog = true
    }
  }
}
</script>
<style lang="less" scoped>
.sk-page {
  width: 100%;
  height: 100%;
}
</style>
